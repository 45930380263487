import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Trans } from "react-i18next";

class GeoDetails extends Component {
  render() {
    return (
      <Container>
        <div className="services-content row">
          <h3>
            <Trans i18nKey="common:services.details.title" />
          </h3>
        </div>
        <div className="services-content row">
          <div className="col-6">
            <ul>
              <li>
                <Trans i18nKey="common:services.geo.details.item_01" />
                <ul>
                  <li>
                    <Trans i18nKey="common:services.geo.details.item_01_1" />
                  </li>
                  <li>
                    <Trans i18nKey="common:services.geo.details.item_01_2" />
                  </li>
                  <li>
                    <Trans i18nKey="common:services.geo.details.item_01_3" />
                  </li>
                  <li>
                    <Trans i18nKey="common:services.geo.details.item_01_4" />
                  </li>
                  <li>
                    <Trans i18nKey="common:services.geo.details.item_01_5" />
                  </li>
                  <li>
                    <Trans i18nKey="common:services.geo.details.item_01_6" />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-6">
            <ul>
              <li>
                <Trans i18nKey="common:services.geo.details.item_02" />
              </li>
              <li>
                <Trans i18nKey="common:services.geo.details.item_03" />
                <ul>
                  <li>
                    <Trans i18nKey="common:services.geo.details.item_03_1" />
                  </li>
                  <li>
                    <Trans i18nKey="common:services.geo.details.item_03_2" />
                  </li>
                </ul>
              </li>
              <li>
                <Trans i18nKey="common:services.geo.details.item_04" />
              </li>
            </ul>
          </div>
        </div>
      </Container>
    );
  }
}

export default GeoDetails;
