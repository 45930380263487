import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Trans } from "react-i18next";

import ConsultingHome from "./consulting/home";
import GeoHome from "./geo/home";
import OtherHome from "./others/home";

import ConsultingDetails from "./consulting/details";
import GeoDetails from "./geo/details";
import OtherDetails from "./others/details";

import ConsultingProjects from "./consulting/projects";
import GeoProjects from "./geo/projects";
import OtherProjects from "./others/projects";

class Services extends Component {
  render() {
    return (
      <Container>
        <div style={{ marginTop: 15, marginBottom: 10 }}>
          <ul
            className="nav nav-tabs md-tabs nav-justified primary-color"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className="nav-link active"
                data-toggle="tab"
                href="#consulting_panel"
                role="tab"
              >
                <i className="fas fa-user pr-2"></i>
                <Trans i18nKey="common:services.consulting.title">
                  Consultor&iacute;a
                </Trans>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-toggle="tab"
                href="#geotechnical_panel"
                role="tab"
              >
                <i className="fas fa-heart pr-2"></i>
                <Trans i18nKey="common:services.geotechnical.title">
                  Geotechnical
                </Trans>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-toggle="tab"
                href="#others_panel"
                role="tab"
              >
                <i className="fas fa-heart pr-2"></i>
                <Trans i18nKey="common:services.others.title">others</Trans>
              </a>
            </li>
          </ul>

          {/* First tab content */}
          <div className="tab-content">
            <div
              className="tab-pane fade in show active"
              id="consulting_panel"
              role="tabpanel"
            >
              <div className="row">
                <div className="col-md-3">
                  <ul
                    className="nav md-pills pills-primary flex-column"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#consulting_home"
                        role="tab"
                      >
                        <Trans i18nKey="common:services.bullet.home">
                          Home
                        </Trans>
                        <i className="fas fa-download ml-2"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#consulting_details"
                        role="tab"
                      >
                        <Trans i18nKey="common:services.bullet.details">
                          Details
                        </Trans>
                        <i className="fas fa-file-alt ml-2"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#consulting_projects"
                        role="tab"
                      >
                        <Trans i18nKey="common:services.bullet.projects">
                          Projects
                        </Trans>
                        <i className="fas fa-address-card ml-2"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-9">
                  <div className="tab-content vertical">
                    <div
                      className="tab-pane fade in show active"
                      id="consulting_home"
                      role="tabpanel"
                    >
                      <ConsultingHome />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="consulting_details"
                      role="tabpanel"
                    >
                      <ConsultingDetails />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="consulting_projects"
                      role="tabpanel"
                    >
                      <ConsultingProjects />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Second tab content */}
            <div
              className="tab-pane fade"
              id="geotechnical_panel"
              role="tabpanel"
            >
              <div className="row">
                <div className="col-md-3">
                  <ul
                    className="nav md-pills pills-primary flex-column"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#geo_home"
                        role="tab"
                      >
                        <Trans i18nKey="common:services.bullet.home">
                          Home
                        </Trans>
                        <i className="fas fa-download ml-2"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#geo_details"
                        role="tab"
                      >
                        <Trans i18nKey="common:services.bullet.details">
                          Details
                        </Trans>
                        <i className="fas fa-file-alt ml-2"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#geo_projects"
                        role="tab"
                      >
                        <Trans i18nKey="common:services.bullet.projects">
                          Projects
                        </Trans>
                        <i className="fas fa-address-card ml-2"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-9">
                  <div className="tab-content vertical">
                    <div
                      className="tab-pane fade in show active"
                      id="geo_home"
                      role="tabpanel"
                    >
                      <GeoHome />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="geo_details"
                      role="tabpanel"
                    >
                      <GeoDetails />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="geo_projects"
                      role="tabpanel"
                    >
                      <GeoProjects />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Third panel */}
            <div className="tab-pane fade" id="others_panel" role="tabpanel">
              <div className="row">
                <div className="col-md-3">
                  <ul
                    className="nav md-pills pills-primary flex-column"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#others_home"
                        role="tab"
                      >
                        <Trans i18nKey="common:services.bullet.home">
                          Home
                        </Trans>
                        <i className="fas fa-download ml-2"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#others_details"
                        role="tab"
                      >
                        <Trans i18nKey="common:services.bullet.details">
                          Details
                        </Trans>
                        <i className="fas fa-file-alt ml-2"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#others_projects"
                        role="tab"
                      >
                        <Trans i18nKey="common:services.bullet.projects">
                          Projects
                        </Trans>
                        <i className="fas fa-address-card ml-2"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-9">
                  <div className="tab-content vertical">
                    <div
                      className="tab-pane fade in show active"
                      id="others_home"
                      role="tabpanel"
                    >
                      <OtherHome />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="others_details"
                      role="tabpanel"
                    >
                      <OtherDetails />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="others_projects"
                      role="tabpanel"
                    >
                      <OtherProjects />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default Services;
