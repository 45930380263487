import React from "react";
import { useTranslation, Trans } from "react-i18next";

function NavBar() {
  const { i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <a className="navbar-brand" href="#">
        <img src="logo.png" width="162" height="65" alt="" />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse flex-grow-1 text-righ"
        id="navbarNav"
      >
        <ul className="navbar-nav ml-auto flex-nowra">
          <li className="nav-item">
            <a className="nav-link" href="/">
              <Trans i18nKey="common:navbar.home">Home</Trans>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/services">
              <Trans i18nKey="common:navbar.services">Services</Trans>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/about">
              <Trans i18nKey="common:navbar.about">About Us</Trans>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default NavBar;
