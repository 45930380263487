import React from "react";
import "./App.css";
import NavBar from "./components/navBar";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/home";
import Services from "./components/services";
import About from "./components/about";
import Footer from "./components/footer";

function App() {
  return (
    <React.Fragment>
      <NavBar />
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/services" component={Services} />
          <Route path="/about" component={About} />
        </Switch>
      </Router>
      <Footer />
    </React.Fragment>
  );
}

export default App;
