import React from "react";
import { useTranslation, Trans } from "react-i18next";

function Footer() {
  const { i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-bottom">
      <a className="navbar-brand" href="#">
        <img src="logo.png" width="162" height="65" alt="" />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#footerBar"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse flex-column ml-lg-0 ml-3 text-righ"
        id="footerBar"
      >
        <ul className="navbar-nav ml-auto flex-nowra">
          <li className="nav-item">
            <a className="nav-link" href="/about">
              <Trans i18nKey="common:navbar.about">About Us</Trans>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/about">
              email:test@test.com
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              Tel. 2286 4881
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="https://www.facebook.com/STC-GRUPO-478050816262829/"
            >
              <img src="facebook.png" width="32" height="32" alt="facebook" />
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="https://www.linkedin.com/company/stc-grupo-empresarial-setecoop-/"
            >
              <img src="linkedin.png" width="32" height="32" alt="linkedin" />
            </a>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto flex-nowra">
          <li className="nav-item">
            <a
              className="nav-link"
              href="#"
              onClick={() => changeLanguage("es")}
            >
              <img src="es.png" className="flag" />
            </a>
            <a
              className="nav-link"
              href="#"
              onClick={() => changeLanguage("en")}
            >
              <img src="en.png" className="flag" />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Footer;
