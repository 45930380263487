import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Trans } from "react-i18next";

class ConsultingDetails extends Component {
  render() {
    return (
      <Container>
        <div className="services-content row">
          <h3>
            <Trans i18nKey="common:services.details.title" />
          </h3>
        </div>
        <div className="services-content row">
          <div className="col-6">
            <ul>
              <li>
                <Trans i18nKey="common:services.consulting.details.item_01" />
                <ul>
                  <li>
                    <Trans i18nKey="common:services.consulting.details.item_01_1" />
                  </li>
                  <li>
                    <Trans i18nKey="common:services.consulting.details.item_01_2" />
                  </li>
                  <li>
                    <Trans i18nKey="common:services.consulting.details.item_01_3" />
                  </li>
                </ul>
              </li>
              <li>
                <Trans i18nKey="common:services.consulting.details.item_02" />
                <ul>
                  <li>
                    <Trans i18nKey="common:services.consulting.details.item_02_1" />
                  </li>
                  <li>
                    <Trans i18nKey="common:services.consulting.details.item_02_2" />
                  </li>
                  <li>
                    <Trans i18nKey="common:services.consulting.details.item_02_3" />
                  </li>
                </ul>
              </li>
              <li>
                <Trans i18nKey="common:services.consulting.details.item_03" />
                <ul>
                  <li>
                    <Trans i18nKey="common:services.consulting.details.item_03_1" />
                  </li>
                  <li>
                    <Trans i18nKey="common:services.consulting.details.item_03_2" />
                  </li>
                  <li>
                    <Trans i18nKey="common:services.consulting.details.item_03_3" />
                  </li>
                  <li>
                    <Trans i18nKey="common:services.consulting.details.item_03_4" />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-6">
            <ul>
              <li>
                <Trans i18nKey="common:services.consulting.details.item_04" />
              </li>
              <li>
                <Trans i18nKey="common:services.consulting.details.item_05" />
              </li>
              <li>
                <Trans i18nKey="common:services.consulting.details.item_06" />
              </li>
              <li>
                <Trans i18nKey="common:services.consulting.details.item_07" />
              </li>
              <li>
                <Trans i18nKey="common:services.consulting.details.item_08" />
              </li>
              <li>
                <Trans i18nKey="common:services.consulting.details.item_09" />
              </li>
              <li>
                <Trans i18nKey="common:services.consulting.details.item_10" />
                <ul>
                  <li>
                    <Trans i18nKey="common:services.consulting.details.item_10_1" />
                  </li>
                  <li>
                    <Trans i18nKey="common:services.consulting.details.item_10_2" />
                  </li>
                  <li>
                    <Trans i18nKey="common:services.consulting.details.item_10_3" />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    );
  }
}

export default ConsultingDetails;
