import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Trans } from "react-i18next";

class ConsultingProjects extends Component {
  render() {
    return (
      <Container>
        <div className="services-content row">
          <p>ConsultingProjects</p>
        </div>
      </Container>
    );
  }
}

export default ConsultingProjects;
