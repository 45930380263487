import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { Trans } from "react-i18next";

class About extends Component {
  render() {
    const mapStyles = {
      height: "500px",
      marginBottom: 10,
      padding: 0
    };

    return (
      <Container>
        <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
          <div className="col-12 col-lg-4 d-flex align-items-stretch">
            <div
              className="card text-white bg-primary "
              style={{
                marginTop: 10,
                marginBottom: 10,
                width: "100%"
              }}
            >
              <div className="card-body">
                <h5 className="card-title">
                  <Trans i18nKey="common:about.mission.title">Misión</Trans>
                </h5>
                <p className="card-text">
                  <Trans i18nKey="common:about.mission.body">
                    “Brindar servicios interdisciplinarios de excelencia en
                    consultoría y construcción en las áreas de ingeniería y
                    arquitectura en procura de maximizar los negocios de
                    nuestros clientes, propiciando el desarrollo profesional y
                    humano de los asociados y colaboradores dentro de un marco
                    de responsabilidad social empresarial y de protección del
                    medio ambiente”
                  </Trans>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 d-flex align-items-stretch">
            <div
              className="card text-white bg-info"
              style={{ marginTop: 10, marginBottom: 10, width: "100%" }}
            >
              <div className="card-body">
                <h5 className="card-title">
                  <Trans i18nKey="common:about.vision.title">Visión</Trans>
                </h5>
                <p className="card-text">
                  <Trans i18nKey="common:about.vision.body">
                    “Ser una empresa líder a nivel latinoamericano en
                    consultoría y construcción, reconocida por sus clientes por
                    el fiel cumplimiento en la ejecución de los proyectos en el
                    plazo, calidad y costo convenido”
                  </Trans>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 d-flex align-items-stretch">
            <div
              className="card text-white bg-success"
              style={{ marginTop: 10, marginBottom: 10, width: "100%" }}
            >
              <div className="card-body">
                <h5 className="card-title">
                  <Trans i18nKey="common:about.values.title">Valores</Trans>
                </h5>
                <ul className="card-text">
                  <li>
                    <Trans i18nKey="common:about.values.item_1">
                      Compromiso
                    </Trans>
                  </li>
                  <li>
                    <Trans i18nKey="common:about.values.item_2">
                      Excelencia
                    </Trans>
                  </li>
                  <li>
                    <Trans i18nKey="common:about.values.item_3">
                      Flexibilidad
                    </Trans>
                  </li>
                  <li>
                    <Trans i18nKey="common:about.values.item_4">
                      Solidaridad
                    </Trans>
                  </li>
                  <li>
                    <Trans i18nKey="common:about.values.item_5">
                      Transparencia
                    </Trans>
                  </li>
                  <li>
                    <Trans i18nKey="common:about.values.item_6">
                      Honestidad
                    </Trans>
                  </li>
                  <li>
                    <Trans i18nKey="common:about.values.item_7">
                      Resultados
                    </Trans>
                  </li>
                  <li>
                    <Trans i18nKey="common:about.values.item_8">
                      Competencia
                    </Trans>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ margin: 0, marginTop: 50 }}>
          <div style={mapStyles} className="col-12">
            <Map
              google={this.props.google}
              zoom={17}
              style={mapStyles}
              initialCenter={{ lat: 9.920747, lng: -84.070153 }}
              className="col-12"
            >
              <Marker position={{ lat: 9.920747, lng: -84.070154 }} />
            </Map>
          </div>
        </div>
      </Container>
    );
  }
}

//export default About;

export default GoogleApiWrapper({
  apiKey: "AIzaSyAXCQhX-g3iKYFwuF1qxqnJ7u87NTyYzQw"
})(About);
